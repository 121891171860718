import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Particles from "./../images/svg/bg-3.svg"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Sivua ei löytynyt!" />
    <section
      className="slice slice-lg vh-100 bg-gradient-primary overflow-hidden"
      data-offset-top="#header-main"
    >
      <div className="bg-absolute-cover vh-100 overflow-hidden">
        <figure className="w-100">
          <img src={Particles} alt={"SVG Particles"} />
        </figure>
      </div>
      <div className="container h-100 d-flex align-items-center position-relative zindex-100">
        <div className="col">
          <div className="row justify-content-center">
            <div className="col-lg-7 text-center">
              <h6 className="h1 mb-5 font-weight-400 text-white">
                Sivua ei löytynyt
              </h6>
              <Link
                to={"/"}
                className="btn btn-white btn-icon rounded-pill hover-translate-y-n3"
              >
                Tästä etusivulle
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="position-absolute bottom-0 right-4 overflow-hidden">
        <figure className="w-50"></figure>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
